<template>
	<div class="h-100">
		<TypeOrderForm :is-update="isUpdate" @cancel="goToList" @submit="updateOrderType" />
	</div>
</template>

<script>
import { UPDATE_TYPE_ORDER, TYPE_ORDER_DETAIL } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
import { RESET_STATE } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("typeOrder")

export default {
	name: "TypeOrderDetail",
	components: {
		TypeOrderForm: () => import("../components/TypeOrderForm"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
	},
	created() {
		this.getOrderTypeDetail()
	},
	beforeDestroy() {
		this.RESET_STATE()
	},
	methods: {
		...mapActions({ UPDATE_TYPE_ORDER, TYPE_ORDER_DETAIL }),
		...mapMutations({ RESET_STATE }),
		async getOrderTypeDetail() {
			const params = {
				id: this.id,
			}
			await this.TYPE_ORDER_DETAIL(params)
		},
		updateOrderType: async function (params) {
			this.showLoading()
			if (params.file) {
				// upload file
				const response = await this.$store.dispatch("uploadFile", params.file)

				if (response.ok) {
					delete params.file
					params["tokens"] = response.tokens
				}
			}

			// create type order
			await this.UPDATE_TYPE_ORDER(params)
			this.hideLoading()
		},
		goToList() {
			this.$router.push({ name: "TypeOrderManagement" })
		},
	},
}
</script>
